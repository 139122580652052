/* Roots */
body {
    background-color: #101010;
    color: #dddbcd;
    font-family: serif;
    font-size: 1.1em;

    text-align: center;
}
.welcome {
    margin-top: 1em;
}
.display {
    margin-top: 1em;
}

/* Links */
a:link {
    color: #9fdef0;
}
a:visited {
    color: #5f9ea0;
}
a:hover {
    color: whitesmoke;
}

/* Buttons */
button {
    background-color: #9fdef0;
    padding: 0.5em;
    margin: 0.1em;
    font-family: serif;
    font-size: 1em;
    border: none;
    cursor: pointer;
    border-radius: 0.1em;
}
button:hover {
    background-color: #3f7e80;
    color: white;
}
button.active {
    background-color: #3f7e80;
    color: white;
    border: none;
}
button.active:hover {
    background-color: #2f6e70;
}
.save-button {
    margin: 1em;
    position: fixed;
    bottom: 0;
    right: 0;
    font-family: monospace;
}
.code-button {
    margin: 1em;
    position: fixed;
    bottom: 0;
    left: 0;
    font-family: monospace;
}
button .cb-hover {
    display: none;
}
button:hover .cb-normal {
    display: none;
}
button:hover .cb-hover {
    display: block;
}

/* The Json Example button */
.example {
    background-color: #101010;
    color: #dddbcd;
    text-decoration: underline;
}
.example:hover {
    background-color: #9fdef0;
    color: #101010;
}

/* The input fields and buttons */
textarea {
    background-color: whitesmoke;
    color: #101010;
    border: none;
    border-radius: 0.1em;
    padding: 0.5em;
}
input[type=file] {
    display: none;
}
.input_file_label {
    background-color: #9fdef0;
    color: #101010;
    padding: 0.5em;
    margin: 0.1em;
    font-family: serif;
    font-size: 1em;
    border: none;
    cursor: pointer;
    border-radius: 0.1em;

    display: inline-block;
}
.input_file_label:hover {
    background-color: #3f7e80;
    color: white;
}

.pr-call {
    margin-top: 4em;
    margin-bottom: 4em;
    font-size: 0.8em;
}

/* The loading box during inference */
.loading {
    margin-top: 4em;
}
/* The box containing the actual code/json/... for one output type */
.output {
    border-radius: 0.5em;
    text-align: left;
    overflow-x: scroll;
}

/* The wrapper for all outputs, its margins increase with screen size. */
.output-wrapper {
    margin-top: 1em;
}
@media only screen and (min-width: 768px) { /* Medium */
    .output-wrapper {
        margin: 1em;
    }
}
@media only screen and (min-width: 992px) { /* Large */
    .output-wrapper {
        margin: 3em;
        margin-top: 4em;
    }

    /* The wrapper for the selection of the displayed output, 
       on large screens it sticks to the top. */
    .output-options {
        margin-top: 1em;
        position: fixed;
        top: 0;
        width: 100%;
    }
}
@media only screen and (min-width: 1200px) { /* Extra large */
    .output-wrapper {
        margin: 5em;
        margin-top: 4em;
    }
}
  
